

































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
@Component({
  components: {
    Mixin,
  },
})
export default class memberTable extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public list!: any;
  @Prop({
    default: () => {
      return {
        left: "场次名称",
        right: "会员政策",
      };
    },
    type: Object,
  })
  public tableHead!: any;
  @Prop({
    default: () => {
      return "discountDes";
    },
    type: String,
  })
  public source!: unknown;
}
