























































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { CountDown, Icon } from "vant";
@Component({
  components: {
    Mixin,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
  },
})
export default class ActivityPopup extends Mixins(Mixin) {
  @Prop({ type: Array, default: [] })
  public activityList!: Array<good.FlashSaleDto>;
  @Prop({ type: Number, default: 0 }) public productId!: number;
  @Prop({ type: String, default: "" }) public activityId!: number;
  goActivity(activityId: string): void {
    this.$router.push(`/activity/${activityId}/${this.productId}/null`);
  }
  getActMsg(activityState: string | undefined): {
    status: string;
  } {
    let obj = {
      status: "",
    };
    if (activityState === "0") {
      obj.status = "已售罄";
    } else if (activityState === "1") {
      obj.status = "距离结束";
    } else if (activityState === "2") {
      obj.status = "距离开始";
    }
    return obj;
  }
  countDownFinish(index: number): void {
    this.$emit("refresh", index);
  }
}
