


















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Calendar } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import dayjs from "dayjs";

declare type CalendarDate = {
  date: Date;
  type: string;
  topInfo: string;
  text: number;
  bottomInfo: string;
  className?: string;
};

@Component({
  components: { [Calendar.name]: Calendar },
})
export default class MyCalendar extends Mixins(Mixin) {
  @Prop({ type: String, default: false })
  public isCalendar!: string;
  @Prop({ type: Array, default: [] })
  public showDateArr!: Array<string>; //演出可选日期
  @Prop({ type: Array, default: [] })
  public showDateList!: Array<string>; //格式化演出可选日期
  public currentYear = "";
  public currentMonth = "";
  public maxDate: Date = new Date("2021/01/01"); // 需要先初始化一下，否则组件会认为没有这个属性
  public defaultDate: Date | null = null; // 默认选中日期
  public exitCommon: Date = new Date(); // 是否存在当前选中的日期存在多个场次的情况

  initData(): void {
    this.getMaxDate();
  }

  getDate(timeStamp: number): Date {
    return new Date(dayjs(timeStamp).format("YYYY/MM/DD"));
  }

  getMaxDate(): void {
    let list: number[] = [];
    let minDate = new Date();
    if (this.showDateArr.length > 0) {
      this.showDateArr.forEach((item) => {
        // IOS 上，必须用 new Date('2020/01/01') 这种格式
        list.push(Number(item));
      });
      list.sort((a: number, b: number) => b - a);
      // IOS 上，必须用 new Date('2020/01/01') 这种格式
      this.maxDate = this.getDate(list[0]); // 取当前演出时间最大场次
      minDate = this.getDate(list[list.length - 1]); // 最近的演出场次
    }
    // 如果只有一个日期 默认选中
    if (this.showDateList.length === 1) {
      let list1 = this.showDateList[0];
      this.defaultDate = new Date(list1);
      this.choseDay(this.defaultDate);
    } else {
      let arr: Array<string> = [];
      this.showDateList.forEach((showDate) => {
        if (arr.includes(showDate)) {
          let day = showDate;
          this.exitCommon = new Date(day);
        } else {
          arr.push(showDate);
        }
      });
      this.defaultDate = minDate;
      this.choseDay(minDate);
    }
  }

  readerData(day: CalendarDate): CalendarDate {
    let dd = this.dateFormat(day.date, "YYYY/MM/DD");
    let myDate = this.dateFormat(new Date(), "YYYY/MM/DD");
    if (this.showDateList.indexOf(dd) < 0) {
      day.type = "disabled";
    }
    if (dd === myDate) {
      day.bottomInfo = "今天";
      day.className = "today";
    }
    return day;
  }

  choseDay(day: Date): void {
    this.$emit("choose", day);
  }

  // 切换月份
  changeMonth(day: any): void {
    let date = day.title;
    let arr = date.split("年");
    this.currentYear = arr[0];
    let arr2 = arr[1].split("月");
    let mm = "";
    if (Number(arr2[0]) < 10) {
      mm = "0" + arr2[0];
    } else {
      mm = arr2[0];
    }
    this.currentMonth = mm;
  }
}
