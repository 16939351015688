


































































































































import { ProjectDtos } from "@/shims-videos";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Button, Icon, Image, Overlay, ImagePreview } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { videoPlayer } from "vue-video-player"; //导入组件
import "videojs-contrib-hls";
// import { monitorEvent } from "@/utils/youMengMonitor";
import SwiperCore, {
  Swiper,
  EffectCards,
  EffectCoverflow,
  Scrollbar,
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([EffectCards, Autoplay, EffectCoverflow, Scrollbar, Navigation]);

@Component({
  components: {
    Mixin,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Overlay.name]: Overlay,
    [ImagePreview.Component.name]: ImagePreview.Component,
    videoPlayer,
  },
})
export default class showDetail extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public projectDetail!: ProjectDtos;
  public type = "slide";
  public showImgs: Array<string> = [];
  public showMoreDetail = false;
  public dom: HTMLElement | undefined;
  public projectDesp = "";
  public autoplay = false;
  public imgPreview = {
    show: false,
    position: 0,
    index: 1,
  };
  public showVideo = {
    show: false,
    item: "",
    index: 1,
  };
  public showReplay = false;
  public swiper: any = null;
  @Watch("projectDetail", { immediate: true, deep: true })
  changeProjectDetail(val: ProjectDtos): void {
    if (!val) {
      this.showImgs = [];
      this.projectDesp = "";
      return;
    }
    this.showImgs = val.projectImgs!;
    this.$nextTick(() => {
      this.initSwiper();
    });
    let desp = this.projectDetail.projectDesp
      ? this.projectDetail.projectDesp.replace(
          /<img/g,
          "<img referrerPolicy='no-referrer' style='max-width:100%;height:auto;'"
        )
      : "";
    let dealUrl = this.videoUrl(desp);
    // 处理视频在ios会自动弹出的问题
    this.projectDesp = dealUrl.replace(
      /<video/g,
      `<video style='max-width:100%;height:auto' x5-playsinline="true" playsinline="true"  webkit-playsinline="true" loop`
    );
  }
  videoUrl(url: string): string {
    let uri = "";
    if (url.indexOf("http:") > -1) {
      uri = url.replace(/http:/g, "https:");
    } else {
      uri = url;
    }
    return uri;
  }
  get player(): any {
    return (this.$refs.videoPlayer as any).player;
  }
  initSwiper() {
    if (this.swiper) {
      this.swiper.destroy(true);
    }
    if (!this.projectDetail.video && !this.showImgs.length) {
      return;
    }
    this.swiper = new Swiper(".swiper", {
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      spaceBetween: 8,
      slidesPerView: "auto",
    });
    this.swiper.scrollbar.updateSize();
    // }
  }
  handleVideoPreview(item: string | undefined): void {
    if (item) {
      this.showVideo.show = true;
      this.showVideo.item = item;
    }
  }
  onChangeImgPreview(index: number): void {
    this.imgPreview.index = index + 1;
  }
  videoEnd(): void {
    this.showReplay = true;
  }
  reLoad(): void {
    (this.$refs.video as any).currentTime = 0;
    (this.$refs.video as any).play();
    this.autoplay = true;
  }
  closeVideo(): void {
    (this.$refs.video as any).currentTime = 0;
    (this.$refs.video as any).pause();
    this.showVideo.show = false;
  }
}
