












































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Row, Col, Button, Image, CountDown } from "vant";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

let countDownTimer: any = undefined; // 倒计时定时器

@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Image.name]: Image,
    [CountDown.name]: CountDown,
  },
})
export default class Bottom extends Mixins(Mixin) {
  @Prop({ default: "", type: String })
  public activityId!: string;
  @Prop({ default: "", type: String }) public activityState!: string;
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public projectDetail!: good.ProjectDto;
  @Prop({ default: false, type: Boolean }) public productReservation!: boolean;
  @Prop({ default: false }) public showDownload!: boolean;

  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public showInfo!: good.ProjectShowsDto;

  nowMillisecond = new Date().valueOf(); // 当前时间戳

  @Watch("showInfo")
  changeShowInfo(val: good.ProjectShowsDto): void {
    if (val) {
      let time = val.timestamp ? Number(val.timestamp) : 0;
      this.nowMillisecond = new Date(time).valueOf();
    }
  }

  get disabledStatus(): boolean {
    let isDisabled = false;
    if (this.showInfo.source === "POLY") {
      if (
        (this.showInfo.productStatus !== "05" &&
          this.showInfo.productStatus !== "12") ||
        this.activityState === "0" ||
        this.activityState === "2"
      ) {
        isDisabled = true;
      }
    } else {
      if (
        (this.showInfo.productStatus !== "05" &&
          this.showInfo.productStatus !== "10") ||
        this.activityState === "0" ||
        this.activityState === "2"
      ) {
        isDisabled = true;
      }
    }
    // 预售不可用状态也置灰
    if (
      this.projectDetail.isSellOut === "1" ||
      this.projectDetail.isSellOut === "5"
    ) {
      isDisabled = true;
    } else if (this.projectDetail.isSellOut === "4") {
      isDisabled = false;
    }
    return isDisabled;
  }

  beforeDestroy(): void {
    // 组件销毁时，清理定时器
    this.clearCountDownTimer();
  }

  /**
   * 清理倒计时定时任务
   */
  clearCountDownTimer(): void {
    if (countDownTimer) {
      clearInterval(countDownTimer);
    }
    countDownTimer = undefined;
  }

  // 按钮名称
  get buttonName(): string {
    if (this.projectDetail.isSellOut === "1") {
      return "已售罄";
    } else if (this.projectDetail.isSellOut === "4") {
      return "想看";
    } else if (
      (this.projectDetail.isSellOut === "2" ||
        this.projectDetail.isSellOut === "5") &&
      this.showInfo.productStatus === "05"
    ) {
      return "预售";
    }
    let showInfo = this.showInfo;
    let activityState = this.activityState;
    if (this.activityId && activityState === "0") {
      return "已结束";
    } else if (this.activityId && activityState === "2") {
      return "即将开始";
    } else {
      switch (showInfo ? showInfo.productStatus : "") {
        case "01":
          return "待上架";
        case "05":
          return showInfo.chooseSeat ? "选座购买" : "立即购买";
        case "06":
          return "暂停售票";
        case "07":
          return "已结束";
        case "08":
          return "停止售票";
        case "12":
          return "即将开票";
        case showInfo.source === "MY" && "10":
          return showInfo.chooseSeat ? "选座购买" : "立即购买";
      }
    }

    return "";
  }

  // 即将开票按钮上的气泡文本
  get buttonDescription(): string {
    let showInfo = this.showInfo;
    if (!showInfo) {
      return "";
    }
    if (showInfo.productStatus !== "12") {
      return "";
    }

    // let showInfoDetailList = showInfo.showInfoDetailList;
    // let firstShow: good.ShowInfoDetailDto = {};
    // if (!showInfoDetailList || showInfoDetailList.length < 1) {
    //   return "";
    // } else {
    //   showInfoDetailList.forEach((item, index) => {
    //     console.log(item, 88888);
    //     if (item.status === "12") {
    //       firstShow = showInfoDetailList![index];
    //     }
    //   });
    // }
    // console.log(firstShow, 8778);

    // let saleBeginTime = firstShow.saleBeginTime;
    let saleBeginTime = showInfo.saleTime;
    if (!saleBeginTime) {
      console.error("saleBeginTime = ", saleBeginTime);
      return "";
    }
    let gapMillisecond = parseInt(saleBeginTime) - this.nowMillisecond;
    console.log("gapMillisecond = ", gapMillisecond);
    // 大于一小时，展示开票时间
    if (gapMillisecond > 3600000) {
      // return firstShow.saleBeginTimeStr + " 开票";
      return `${this.timestampFormat(
        Number(saleBeginTime),
        "YYYY.MM.DD HH:mm"
      )} 开票`;
    }
    if (gapMillisecond <= 0) {
      this.clearCountDownTimer();
      this.$emit("sale-start");
    }
    let mm: string | undefined = "";
    let ss: string | undefined = "";
    // 小于一小时，展示倒计时
    if (!countDownTimer) {
      // 开启每一秒更新一次当前时间戳的定时任务
      countDownTimer = setInterval(() => {
        this.updateNowMillisecond();
      }, 1000);
    }
    // 倒计时结束，弹出事件，父组件重新获取并更新项目状态，让用户可以买票
    dayjs.extend(duration);
    let minute = dayjs.duration(gapMillisecond).minutes();
    let seconds = dayjs.duration(gapMillisecond).seconds();

    mm = minute >= 10 ? String(minute) : minute === 0 ? "00" : "0" + minute;
    ss = seconds >= 10 ? String(seconds) : seconds === 0 ? "00" : "0" + seconds;
    return `${mm}:${ss}后开票`;
  }

  /**
   * 更新当前时间戳
   */
  updateNowMillisecond(): void {
    this.nowMillisecond = new Date(this.nowMillisecond + 1000).valueOf();
  }

  openTicket(): void {
    this.$emit("openTicket", this.buttonName);
  }

  showPopup(): void {
    this.$emit("showWriteComment");
  }
  gotoDetail(): void {
    this.$emit("gotoDetail");
  }
}
