






























































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Image, Icon, ImagePreview } from "vant";
import { ProjectDtos } from "@/shims-videos";
import { calculateDiscount } from "@/utils/index";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class TimesAndAddr extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public projectDetail!: good.ProjectDto;
  @Prop({ default: true, type: Boolean }) public chooseSeat!: boolean;
  @Prop({ default: "", type: String }) public tourId!: string;

  // 用于展示的到剧院的距离
  get showDistance(): string {
    let longitude = this.LocationModule.longitude;
    let latitude = this.LocationModule.latitude;
    if (longitude < 0 || latitude < 0) {
      // 没有成功定位，不展示距离
      return "...";
    }
    let projectDetail = this.projectDetail;
    if (!projectDetail) {
      return "...";
    }

    return calculateDiscount(
      longitude,
      latitude,
      Number(projectDetail.placeLongitudePoint),
      Number(projectDetail.placeLatitudePoint)
    );
  }

  goToMap(): void {
    let projectDetail = this.projectDetail;
    if (this.tourId) {
      monitorEvent("TourPage_ClickLoc", "点击定位"); // 埋点：巡演详情页，点击定位
    }
    this.$router.push(
      `/map?name=${projectDetail.showPlaceName}&address=${projectDetail.showPlaceAddress}`
    );
  }

  showMap() {
    ImagePreview([this.projectDetail.showSeatMap!]);
  }
}
