
























































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Button, Col, Row, NavBar, Icon, Image, Popup } from "vant";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Popup.name]: Popup,
  },
})
export default class Notice extends Mixins(Mixin) {
  // 项目场次信息
  @Prop({
    type: Object,
    default() {
      return {};
    },
  })
  projectShows!: good.ProjectShowsDto;

  public showPopup = false;

  // 实际的数据
  get noticeList(): Array<good.ProjectNoticeDto> {
    const showInfoDetailList = this.projectShows.showInfoDetailList;
    if (!showInfoDetailList || showInfoDetailList.length < 1) {
      return [];
    }
    const showNotices = showInfoDetailList[0].showNotices;
    return showNotices || [];
  }
}
