





import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import DetailComp from "./detailComp.vue";

@Component({
  components: { Mixin, DetailComp },
})
export default class ProDetail extends Mixins(Mixin) {
  public tourId: string | (string | null)[] = "";
  public productId = -1;

  // @Watch("$route")
  // changeRoute(to: any, from: any): void {
  //   this.productId = Number(from.params.productId);
  //   if (this.productId) {
  //     this.updateData();
  //   }
  // }

  mounted(): void {
    this.updateData();
  }

  updateData(): void {
    this.tourId = this.$route.params.tourId!;
    this.productId = Number(this.$route.params.productId);
    this.InterfaceCacheModule.getCacheProductIds(() => {
      this.$nextTick(() => {
        let detailComp = this.$refs["detail-comp"] as DetailComp;
        if (detailComp) {
          detailComp.refresh();
        }
      });
    });
  }
}
