























































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Row, Col, Image, Icon, ImagePreview } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class Banner extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public projectDetail!: good.ProjectDto;

  openImage(): void {
    console.log("openImage");
    let imageUrl = this.projectDetail.img;
    if (imageUrl) {
      ImagePreview([imageUrl]);
    }
  }
}
